<template>
	<button class="btn-minor size1"
			:disabled="disabled"
			@click.prevent="$emit('click')">
		<slot name="default">{{label}}</slot>
	</button>
</template>

<script>
	export default {
		name: "btn-minor",
		props: {
			label: {},
			disabled: {
				type: Boolean,
				default: false
			}
		}
	}

</script>

<style lang="scss">
	.btn-minor {
		color: $black-100;
		@include transition();

		&:hover {
			color: $black-200;
		}
	}
</style>