<template>
	<div class="basket-items">
		<v-container v-if="mini" class="px-7 py-8">
			<h4 class="text-center mb-4">Ваша корзина</h4>
			<div class="basket-items__list">
			<basket-item v-for="(item, index) of items" :key="'item' + index" :item="item" mini
						 @change="onAmountChangeDebounced"/>
			</div>
			<div class="d-flex justify-space-between align-center text-start mt-4">
				<btn-primary large @click="$router.push({name: 'basket'})">Оформить заказ</btn-primary>
				<h5>ИТОГО: <span class="color-black">{{ total | nf }} ₽</span></h5>
			</div>
		</v-container>
		<v-container v-else>
			<!--      todo margin-bottom main, rename your cart, button, rename components (Carts, Cart)-->
			<h1 class="text-center margin-bottom-main mb-16 h3">Ваша корзина</h1>
			<btn-minor v-if="items" class="basket-items__btn-clear d-none d-sm-block" @click="onClear">
				Очистить корзину
			</btn-minor>

			<basket-item v-for="(item, index) of items" :key="'item' + index" :item="item"
						 @change="onAmountChangeDebounced"/>

			<h4 class="basket-items__subtotal">ИТОГО: <span class="ml-1">{{ total | nf }} ₽</span></h4>
		</v-container>

		<v-dialog
			v-model="isClearing"
			:persistent="isClearingConfirmed"
			:content-class="'popup'"
			max-width="400px">
			<v-card class="glass">

				<v-card-title class="pt-8 justify-center">
					<div class="h5">Вы хотите очистить корзину?</div>
				</v-card-title>
				<v-card-text class="text-center">

				</v-card-text>
				<v-card-actions class="d-flex justify-space-between">
					<!--<btn compact @click="isClearing=false" :disabled="isClearingConfirmed">No</btn>
					<btn compact :busy="isClearingConfirmed" @click="onClearConfirmed">Yes</btn>-->
					<BtnText compact @click="isClearing=false" :disabled="isClearingConfirmed" label="Нет"/>
					<BtnText compact :busy="isClearingConfirmed" @click="onClearConfirmed" label="Да"/>
				</v-card-actions>

			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Cart from "./cart"
import Divider from "@/components/divider";
import {debounce} from "lodash";
import Config from "@/config";
import BasketItem from "@/views/Basket/basket-item";
import BtnPrimary from "@/components/btn-primary";
import BtnText from "@/components/btn-text";
import BtnMinor from "@/components/btn-minor";

export default {
	name: "basket-items",
	components: {
		BtnText,
		BtnMinor,
		BtnPrimary,
		BasketItem,
		Divider,
		Cart
	},
	props: {
		items: Array,
		mini: {
			type: Boolean
		}
	},
	data() {
		return {
			isClearing: false,
			isClearingConfirmed: false,
		}
	},

	computed: {
		total() {
			const productsTotal = this.items.reduce((sum, item) => sum + item.PRICE * Number(item.AMOUNT), 0);
			//const deliveryCost = this.deliveryCost;
			return productsTotal;
		},
		page() {
			return this.$store.state.page.BasketController;
		},
	},
	methods: {
		onClear() {
			this.isClearing = true;
		},
		onAmountChangeDebounced: debounce(function () {

			const items = this.items.map(item=>({
				id: item.ID,	// это BasketItem.ID
				amount: item.AMOUNT
			}));

			this.$store.dispatch('post', {
				action: 'BasketUpdateController',
				params: {
					items
				}
			}).then((result) => {
				this.$store.commit('setSession', result);

				this.isClearing = false;
				this.isClearingConfirmed = false;

			}).catch((error) => {
				// this.error = error;
				this.error = "Произошла ошибка. Пожалуйста, попробуйте снова."
			}).finally(() => {
				this.isClearingConfirmed = false;
			});

		}, Config.DEBOUNCE_INPUT_TIMEOUT),
		onClearConfirmed() {
			this.isClearingConfirmed = true;
			this.$store.dispatch('post', {
				action: 'BasketClearController',
			}).then((result) => {
				this.$store.commit('setSession', result);

				this.isClearing = false;
				this.isClearingConfirmed = false;

			}).catch((error) => {
				// this.error = error;
				this.error = "Произошла ошибка. Пожалуйста, попробуйте снова."
			}).finally(() => {
				this.isClearingConfirmed = false;
			});
		},
	}

}
</script>

<style lang="scss">
.basket-items {
	.container {
		position: relative !important;
	}

	&__list {
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 500px;
	}

	&__subtotal {
		text-align: center;

		@include up($md) {
			text-align: right;
			margin-right: 52px;
		}
	}

	&__btn-clear {
		position: absolute;
		top: 32px;
		right: $container-padding-x;
	}


	h3, h4 {
		color: $primary-500;
	}
}
</style>