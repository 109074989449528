<template>
	<div class="cart">
		<v-row>
			<v-col cols="12" md="6" class="d-flex flex-column flex-md-row align-center pa-0">
				<div class="cart__img-box d-flex justify-center">
					<v-img class="cart__box-img" contain :src="product.PREVIEW_PICTURE" :alt="product.NAME"/>
				</div>
				<!--        <img class="cart-item__box-img mx-auto d-block d-sm-none" :src=product.imgMobile alt="Box">-->
				<div class="mt-8 mt-md-0 ml-md-8 text-center text-md-start">
					<h4 v-html="nameHtml"></h4>
					<div v-html="subnameHtml"></div>
				</div>
			</v-col>
			<v-col cols="12" md="6" class="pa-0 d-md-flex justify-md-end align-md-center">
				<div class="cart__controls text-center text-md-right d-md-flex justify-md-end align-md-center">
					<h4 class="cart__price mt-4 mt-md-0 d-block d-md-none">{{ subtotal | nf(2) }} ₽</h4>
					<counter v-model="item.AMOUNT"
							 class="mx-auto mx-md-0 mt-4 mt-md-0"
							 @change="$emit('change', item)"/>

					<h4 class="cart__price mt-4 mt-md-0 d-none d-md-block">{{ subtotal | nf(2) }} ₽</h4>
					<!--          todo проверить-->
					<button class="cart__delete mt-4 mt-md-0">
						<!--            todo v-img, svgicon-->
						<img class="d-none d-md-block" src="../../assets/Basket/delete.svg" alt="Delete" @click="onDel">
						<div class="d-block d-md-none size1" @click="onDel">Удалить</div>
					</button>
				</div>
			</v-col>
		</v-row>

		<divider class="mt-16"/>

		<v-dialog
				v-model="isDeleting"
				:persistent="isDeletingConfirmed"
				:content-class="'popup'"
				max-width="400px">
			<v-card class="glass">

				<v-card-title class="pt-8 justify-center">
					<div class="h5">Вы хотите удалить этот продукт?</div>
				</v-card-title>
				<v-card-text class="text-center">
					<div v-if="deletingItem" class="h5">{{ deletingItem.NAME }}</div>
				</v-card-text>
				<v-card-actions class="d-flex justify-space-between">
					<BtnText compact @click="isDeleting=false" :disabled="isDeletingConfirmed" label="Нет"/>
					<BtnText compact :busy="isDeletingConfirmed" @click="onDelConfirmed" label="Да"/>
				</v-card-actions>

			</v-card>
		</v-dialog>
	</div>

</template>

<script>
	import Divider from "../../components/divider";
	import Counter from "../../components/counter";
	import BtnText from "../../components/btn-text";

	export default {
		name: "cart",
		components: {
			BtnText,
			Divider,
			Counter
		},
		props: {
			item: Object
		},
		data() {
			return {
				isDeleting: false,
				isDeletingConfirmed: false,
				deletingItem: null,
			}
		},
		computed: {
			product() {
				return this.item.product;
			},
			subtotal() {
				return Number(this.product.PRICE) * Number(this.item.AMOUNT);
			},
			nameHtml() {
				const item = this.product;
				//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
				let name = item.NAME;
				name = name.replace(/(^.+?)(in )?(dark|milk).+$/is, "$1");
				name = name.replace(/(^.+?)filled with .+$/is, "$1");
				name = name.replace(/(^.+?)\d+ g$/is, "$1");
				name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1");
				/*if ( name.match(/(^.+?)(\d+ pcs)$/is) ) {
				  name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1<br>$2");
				  if ( item.WEIGHT ) name += " / "+item.WEIGHT + " g";
				}
				else if ( item.WEIGHT ) name += "<br>"+item.WEIGHT + " g";*/

				return name;
			},
			subnameHtml() {
				const item = this.product;
				if (item.SUBNAME) return item.SUBNAME;
				//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
				let choco = null;
				if (item.NAME.match(/.+?filled with .+$/)) {
					choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
					choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				} else if (item.NAME.match(/.+?(in )?(dark|milk).+$/)) {
					choco = item.NAME.replace(/.+?((in )?(dark|milk).+$)/is, "$1");
					choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				} else if (item.NAME.match(/\d+ g$/)) {
					// todo make this related to item.WEIGHT
					choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
				} else if (item.NAME.match(/\d+ pcs$/)) {
					choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
					if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
				}

				return choco;
			},
		},
		methods: {
			add: function () {
				this.item.counter++;
			},
			sub: function () {
				if (this.item.counter > 0) this.item.counter--;
			},
			onDelConfirmed() {
				this.isDeletingConfirmed = true;
				this.$store.dispatch('post', {
					action: 'BasketDelController',
					params: {
						productId: this.deletingItem.ID
					}
				}).then((result) => {
					this.$store.commit('setSession', result);
					this.isDeleting = false;
					this.isDeletingConfirmed = false;
					this.deletingItem = null;
				}).catch((error) => {
					//console.log("ERROR: ", error);
					// this.error = error;
					this.error = "Произошла ошибка. Пожалуйста, попробуйте снова."
				}).finally(() => {
					this.isDeletingConfirmed = false;
				});
			},
			onDel() {
				this.isDeleting = true;
				this.deletingItem = this.product;
				this.deletingItem.ID = this.item.PRODUCT_ID;
			},
		},
	}
</script>

<style lang="scss">
	.cart {
		width: 100%;
		padding: 32px 0;

		&__box {
			&-img {
				max-width: 280px;
				max-height: 280px;

				@include up($md) {
					max-width: 132px;
					max-height: 132px;
				}
			}
		}

		&__counter {
			width: 100%;
			max-width: 90px;
			height: 41px;
			border: 2px solid $black-50;
		}

		&__controls {
			width: 100%;

			@include up($md) {
				max-width: 320px;
			}
		}

		&__price {
			color: $black !important;
			text-align: center;

			@include up($md) {
				width: 180px;
				text-align: right;
				padding-right: 32px;
			}
		}

		&__delete {

			@include up($md) {
				width: 32px;
				height: 32px;
			}

			.size1 {
				color: $black-100;
			}

			img {
				width: 100%;
				height: 100%;
			}
		}

		h3, h4 {
			color: $primary-500;
		}
	}
</style>